import React, { useEffect, useState } from 'react'; // Added useState import
import Layout from '../components/layout';
import Seo from '../components/seo';
import Modules from '../components/modules';
import CustomHeroBlock from '../components/customHeroBlock';
import CtaI4 from '../components/ctaI4';
import { navigate } from 'gatsby';

const Homepage2Template = ({ pageContext }) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    setModalVisible(pageContext.modalVisible);
  }, [pageContext.modalVisible]);

  return (
    <Layout classNames="home page-template-default page" inverseHeader={false}>
      <Seo
        title="Home"
        seoTitle={pageContext.seo.seoTitle}
        seoDescription={pageContext.seo.seoDescription}
      />
      <section className="content-wrap">
        <CustomHeroBlock />
        <Modules modules={pageContext.modules} />
        <CtaI4 />
      </section>
      {isModalVisible && (
        <>
          {/* Backdrop */}
          <div className="modal-backdrop fade show"></div>

          {/* Modal */}
          <div
            className="modal fade show"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="false"
            style={{ display: 'block' }}
          >
            <div className="modal-dialog" role="document">
              <div
                className="modal-content"
                style={{
                  borderRadius: '15px',
                  fontSize: '2em',
                  padding: '28px 20px 28px 20px',
                }}
              >
                <div className="modal-body text-center">
                  <div className="d-flex justify-content-center align-items-center mb-4">
                    <h5
                      style={{
                        fontSize: '1.5em',
                        textAlign: 'center',
                        flex: 1,
                      }}
                      className="modal-title fw-bold"
                      id="exampleModalLabel"
                    >
                      {pageContext.modalTitle}
                    </h5>
                    <button
                      type="button"
                      className="close btn"
                      onClick={handleCloseModal}
                      aria-label="Close"
                      style={{
                        fontSize: '1.25em',
                        position: 'absolute',
                        top: '-24px',
                        right: '0px',
                      }}
                    >
                      <span aria-hidden="true" style={{ fontSize: '1.25em' }}>
                        &times;
                      </span>
                    </button>
                  </div>
                  <p>{pageContext.modalBody}</p>
                  <button
                    style={{
                      fontSize: '1.15em',
                      borderRadius: '50px',
                      padding: '4px 32px',
                      backgroundColor: '#F58126',
                      color: '#fff',
                    }}
                    type="button"
                    className="btn mt-4"
                    onClick={() => {
                      navigate(pageContext.modalButtonSlug);
                    }}
                  >
                    {pageContext.modalButtonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Homepage2Template;
